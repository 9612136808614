import { useContext } from "react"
import { GlobalContext } from "../../GlobalContext"
import "./scss/Footer.scss"

const Footer = () => {
  const {userProfession} = useContext(GlobalContext)
  return (
    <footer className={`${userProfession? "whiteBG" : ""}`}>
      © Novitim s.r.o. 2024
      </footer>
  )
}

export default Footer