import React from "react";
import "./InputContainer.scss";

const InputContainer = ({ type, label, id, value, set, regexSetting, error, readOnly}) => {
  const inputChange = (value) => {
    let regex = "";

    // if for regex setiting
    if (regexSetting === "name") {
      regex = /^[^\d?<>.:,/_*+\-%;¬`@&#{}()[\]]{0,50}$/;
    }

    if(regexSetting === "email") {
      regex = /^[a-zA-Z0-9._%+-]*@{0,1}[a-zA-Z0-9]*\.{0,1}[a-z]{0,5}$/;
    }
    
    if (regex.test(value) || value === "") {
      set(value);
    }
  };

  return (
    <div className={`containter-input ${error ? "error-border" : ""}`}>
      <div className="intput_box">
        <label
          htmlFor={id}
          style={
            value
              ? {
                  top: "-5px",
                  left: "20px",
                  fontSize: "15px",
                }
              : {}
          }
        >
          {label}
        </label>

        <input
          type={type}
          id={id}
          onChange={(e) => inputChange(e.target.value)}
          value={value}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

export default InputContainer;
