import Header from "./mainLayouts/Header";
import Footer from "./mainLayouts/Footer";
import { Outlet } from "react-router-dom";
import useLocalStorageGet from "../../hooks/useLocalStorageGet";
import { useContext } from "react";
import { GlobalContext } from "../GlobalContext";


const SharedLayouts = () => {
  const {
    setG_localStorage_loading,
    g_localStorage_loading,
  } = useContext(GlobalContext);

  // Load localStorage
  useLocalStorageGet(setG_localStorage_loading, g_localStorage_loading);

  return (
    <>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default SharedLayouts;
