import { useReducer, createContext } from "react";
import GlobalReducer from "./GlobalReducer";

// Global Reducer - Defaultni state
export const mainState = {
  // LocalStorage loading
  g_localStorage_loading: 0,

  //code_phase
  

  // Token
  token_active: false,
  // token_start_time: 0,
  token_active_time: 0,

  token_active_complete: 0, // 0, 1 - time out, 2 - complete

  

  // fetch data
  // wordSet
  // basic
  wordSetBaisc_full: [],
  wordSetBasic_words: [],
  wordSet_language: "",

  // Code
  code_t_title_language: "",
  code_t_title_code: "",
  code_t_info1: "",
  code_t_info2: "",
  code_t_btn: "",
  code_t_language: "",

  // TokenUnactive
  tokenUnactive_original_h1_1: "Překročen limit 45 minut",
  tokenUnactive_original_h1_2: "Průchod byl již dokončen",
  tokenUnactive_original_text: "Přejete si začít znovu?",
  tokenUnactive_original_btn: "Začít znovu",
  tokenUnactive_translate_h1_1: "",
  tokenUnactive_translate_h1_2: "",
  tokenUnactive_translate_text: "",
  tokenUnactive_translate_btn: "",
  tokenUnactive_language: "",
  //RingInfo
  ringInfo_translate_title: "",
  ringInfo_translate_optionMan: "",
  ringInfo_translate_optionWoman: "",
  ringInfo_translate_optionOther: "",
  ringInfo_translate_selectGender: "",
  ringInfo_translate_selectSpecies: "",
  ringInfo_translate_optionSpeciesMan: "",
  ringInfo_translate_optionSpeciesWoman: "",
  ringInfo_translate_instructions: "",
  ringInfo_translate_understand: "",
  ringInfo_language: "",
  // UserForm
  // Texts
  userForm_select_title: "",
  userForm_select_inputName: "",
  userForm_select_inputEmail: "",
  userForm_select_selectCountry: "",
  userForm_select_selectRegion: "",
  userForm_select_selectUSARegion: "",
  userForm_select_selectAge: "",
  userForm_select_selectEducation: "",
  userForm_select_selectMaritalStatus: "",
  userForm_select_selectProfession: "",
  userForm_select_selectPersonsInTheHousehold: "",
  userForm_select_btn: "",
  userForm_select_error: "",
  userForm_select_option_other: "",
  userForm_language: "",
  //options
  countryRegion_options: [],
  education_options: [],
  maritalStatus_options: [],
  profession_options: [],
  userData_options_language:"",
  // region_USA_UK_options: [],

  // Page phase
  phase: 0,
  maxPhase: 0,

  // User Data
  // Language + gender
  userLanguage: "CS",
  userGender: null,
  userSpecies: null,
  userName: "",
  userEmail: "",
  userCountry: "",
  userRegion: "",
  usaRegion: "",
  userAge: "",
  userEducation: "",
  userMaritalStatus: "",
  userProfession: "",
  userPersonsHouse: "",

  // Timer
  timerIsActive: false,
  timerMillisecondsReset: false,
  timerWordColorSetMilliseconds: [],

  timerWordTimerUserResponses: [], // LS

  timerBackBTN: false,
  timerNumberBack: 0,
  timerNumberBackResponses: [], // LS
  // -------
  fristColorSet: [],
  lastColorSet: [],
  oneWordColorArray: [], // 3 color's 1 word

  actualWordIndex: 0,
  userResponses: [], // array - Color set for 1 word

  urlServer: "/api", // DMAPS
  //  urlServer: "http://localhost:5000", // Local server
  // urlServer: "https://novitim-ring-f96a54f9d93b.herokuapp.com", // Heroku
  
};

export const GlobalContext = createContext(); // Context - create

// -----------------------------------------------------------------------------------------


















export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(GlobalReducer, mainState);

  // token
  const setToken_active = (token_active, /*token_start_time,*/ token_active_time) => {
    dispatch({
      type: "SET_TOKEN_ACTIVE",
      payload: {
        token_active,
        // token_start_time,
        token_active_time
      } 
    })
  }
  // token complete
  const setToken_active_complete = (token_active_complete) => {
    dispatch({
      type: "SET_TOKEN_ACTIVE_COMPLETE",
      payload: token_active_complete
    })
  }

  // localStorage loading
  const setG_localStorage_loading = (g_localStorage_loading) => {
    dispatch({
      type: "SET_G_LOCALSTORAGE_LOADING",
      payload: g_localStorage_loading,
    });
  };

  // Page Phase
  const setPhase = (phase, maxPhase) => {
    dispatch({
      type: "SET_PHASE",
      payload: {
        phase, 
        maxPhase
      },
    });
  };

  // -----------------------

  // Fetch
  // WordSet Basic
  const setWordSet_basic = (wordSetBaisc_full, wordSetBasic_words, wordSet_language) => {
    dispatch({
      type: "SET_COLOR_SET_BASIC",
      payload: {
        wordSetBaisc_full,
        wordSetBasic_words,
        wordSet_language,
      },
    });
  };

  // translate
  // code
  const setCode_translate = (
    code_t_title_language,
    code_t_title_code,
    code_t_info1,
    code_t_info2,
    code_t_btn,
    code_t_language,
  ) => {
    dispatch({
      type: "SET_CODE_TRANSLATE",
      payload: {
        code_t_title_language,
        code_t_title_code,
        code_t_info1,
        code_t_info2,
        code_t_btn,
        code_t_language
      }
    })
  }

  // TokenUnactive
  const setTokenUnactive_translate = (
    tokenUnactive_translate_h1_1,
    tokenUnactive_translate_h1_2,
    tokenUnactive_translate_text,
    tokenUnactive_translate_btn,
    tokenUnactive_language,
  ) => {
    dispatch({
      type: "SET_TOKEN_UNACTIVE_TRANSLATE",
      payload: {
        tokenUnactive_translate_h1_1,
        tokenUnactive_translate_h1_2,
        tokenUnactive_translate_text,
        tokenUnactive_translate_btn,
        tokenUnactive_language
      }
    })
  }

  // RungInfo
  const setRingInfo_translate = (
    ringInfo_translate_title,
    ringInfo_translate_optionMan,
    ringInfo_translate_optionWoman,
    ringInfo_translate_optionOther,
    ringInfo_translate_selectGender,
    ringInfo_translate_selectSpecies,
    ringInfo_translate_optionSpeciesMan,
    ringInfo_translate_optionSpeciesWoman,
    ringInfo_translate_instructions,
    ringInfo_translate_understand,
    ringInfo_language,
  ) => {
    dispatch({
      type: "SET_RING_INFO_TRANSLATE",
      payload: {
        ringInfo_translate_title,
        ringInfo_translate_optionMan,
        ringInfo_translate_optionWoman,
        ringInfo_translate_optionOther,
        ringInfo_translate_selectGender,
        ringInfo_translate_selectSpecies,
        ringInfo_translate_optionSpeciesMan,
        ringInfo_translate_optionSpeciesWoman,
        ringInfo_translate_instructions,
        ringInfo_translate_understand,
        ringInfo_language,
      }
    })
  }
  // userForm selects
  const setUserForm_selects = (
    userForm_select_title,
    userForm_select_inputName,
    userForm_select_inputEmail,
    userForm_select_selectCountry,
    userForm_select_selectRegion,
    userForm_select_selectUSARegion,
    userForm_select_selectAge,
    userForm_select_selectEducation,
    userForm_select_selectMaritalStatus,
    userForm_select_selectProfession,
    userForm_select_selectPersonsInTheHousehold,
    userForm_select_btn,
    userForm_select_error,
    userForm_select_option_other,
    userForm_language
  ) => {
    dispatch({
      type: "SET_USER_FORM_SELECTS",
      payload: {
        userForm_select_title,
        userForm_select_inputName,
        userForm_select_inputEmail,
        userForm_select_selectCountry,
        userForm_select_selectRegion,

        userForm_select_selectUSARegion,
        userForm_select_selectAge,
        userForm_select_selectEducation,
        userForm_select_selectMaritalStatus,
        userForm_select_selectProfession,
        userForm_select_selectPersonsInTheHousehold,
        userForm_select_btn,
        userForm_select_error,
        userForm_select_option_other,
        userForm_language,
      },
    });
  };
  // userform options
  const setFormData_options = (
    countryRegion_options,
    education_options,
    maritalStatus_options,
    profession_options,
    userData_options_language,
  ) => {
    dispatch({
      type: "SET_FORM_DATA_OPTIONS",
      payload: {
        countryRegion_options,
        education_options,
        maritalStatus_options,
        profession_options,
        userData_options_language,
      },
    });
  };

  // -----------------------

  // Language
  const setUserLanguage = (userLanguage) => {
    dispatch({
      type: "SET_USER_LANGUAGE",
      payload: userLanguage,
    });
  };

  const setUserGender = (userGender) => {
    dispatch({
      type: "SET_USER_GENDER",
      payload: userGender,
    });
  };

  const setUserSpecies = (userSpecies) => {
    dispatch({
      type: "SET_USER_SPECIES",
      payload: userSpecies,
    });
  };

  // User Data
  const setUser = (
    userName,
    userEmail,
    userCountry,
    userRegion,
    usaRegion,
    userAge,
    userEducation,
    userMaritalStatus,
    userProfession,
    userPersonsHouse
  ) => {
    dispatch({
      type: "SET_USER",
      payload: {
        userName,
        userEmail,
        userCountry,
        userRegion,
        usaRegion,

        userAge,
        userEducation,
        userMaritalStatus,
        userProfession,
        userPersonsHouse,
      },
    });
  };

  // Timer -----------------
  const setTimerIsActive = (timerIsActive) => {
    dispatch({
      type: "SET_TIMER_IS_ACTIVE",
      payload: timerIsActive,
    });
  };
  const setTimerMillisecondsReset = (timerMillisecondsReset) => {
    dispatch({
      type: "SET_TIMER_MILLISECONDS_RESET",
      payload: timerMillisecondsReset,
    });
  };
  const setTimerWordColorSetMilliseconds = (timerWordColorSetMilliseconds) => {
    dispatch({
      type: "SET_TIMER_WORD_COLOR_SET_MILLISECONDS",
      payload: timerWordColorSetMilliseconds,
    });
  };

  const setTimerWordTimerUserResponses = (timerWordTimerUserResponses) => {
    dispatch({
      type: "SET_TIMER_WORD_USER_RESPONSES",
      payload: timerWordTimerUserResponses,
    });
  };

  // Back
  const setTimerBackBTN = (timerBackBTN) => {
    dispatch({
      type: "SET_TIMER_BACK_BTN",
      payload: timerBackBTN,
    });
  };

  const setTimerNumberBack = (timerNumberBack) => {
    dispatch({
      type: "SET_TIMER_NUMBER_BACK",
      payload: timerNumberBack,
    });
  };
  const setTimerNumberBackResponses = (timerNumberBackResponses) => {
    dispatch({
      type: "SET_TIMER_NUMBER_BACK_RESPONSES",
      payload: timerNumberBackResponses,
    });
  };
  // ---------------------------------

  // Frist color set
  const setFristColorSet = (fristColorSet) => {
    dispatch({
      type: "SET_FRIST_COLOR_SET",
      payload: fristColorSet,
    });
  };
  // Last color set
  const setLastColorSet = (lastColorSet) => {
    dispatch({
      type: "SET_LAST_COLOR_SET",
      payload: lastColorSet,
    });
  };

  const setOneWordColorArray = (oneWordColorArray) => {
    dispatch({
      type: "SET_ONE_WORD_COLOR_ARRAY",
      payload: oneWordColorArray,
    });
  };

  const setColorObject = (actualWordIndex, userResponses) => {
    dispatch({
      type: "SET_COLOR_OBJECT",
      payload: {
        actualWordIndex,
        userResponses,
      },
    });
  };


  const resetAll = () => {
    dispatch({
      type: "RESET_ALL",
    });
  };

  // ---------------------------------------------------------------------------------

  return (
    <GlobalContext.Provider // Context - extract
      value={{ // Reduce for context
        

        // token
        // token active
        setToken_active,
        token_active: state.token_active,
        // token_start_time: state.token_start_time,
        token_active_time: state.token_active_time,
        // token complete
        setToken_active_complete,
        token_active_complete: state.token_active_complete,


        // LocalStorage loading
        setG_localStorage_loading,
        g_localStorage_loading: state.g_localStorage_loading,

        // Page Phase
        setPhase,
        phase: state.phase,
        maxPhase: state.maxPhase,

        // translate
          // CODE
          setCode_translate,
          code_t_title_language: state.code_t_title_language,
          code_t_title_code: state.code_t_title_code,
          code_t_info1: state.code_t_info1,
          code_t_info2: state.code_t_info2,
          code_t_btn: state.code_t_btn,
          code_t_language: state.code_t_language,
          // TokenUnactive - original + translate
            // original
            tokenUnactive_original_h1_1: state.tokenUnactive_original_h1_1,
            tokenUnactive_original_h1_2: state.tokenUnactive_original_h1_2,
            tokenUnactive_original_text: state.tokenUnactive_original_text,
            tokenUnactive_original_btn: state.tokenUnactive_original_btn,
            // traslate
            setTokenUnactive_translate,
            tokenUnactive_translate_h1_1: state.tokenUnactive_translate_h1_1,
            tokenUnactive_translate_h1_2: state.tokenUnactive_translate_h1_2,
            tokenUnactive_translate_text: state.tokenUnactive_translate_text,
            tokenUnactive_translate_btn: state.tokenUnactive_translate_btn,
            tokenUnactive_language: state.tokenUnactive_language,
          // RingInfo - translate
          setRingInfo_translate,
          ringInfo_translate_title: state.ringInfo_translate_title,
          ringInfo_translate_optionMan: state.ringInfo_translate_optionMan,
          ringInfo_translate_optionWoman: state.ringInfo_translate_optionWoman,
          ringInfo_translate_optionOther: state.ringInfo_translate_optionOther,
          ringInfo_translate_selectGender: state.ringInfo_translate_selectGender,
          ringInfo_translate_selectSpecies: state.ringInfo_translate_selectSpecies,
          ringInfo_translate_optionSpeciesMan: state.ringInfo_translate_optionSpeciesMan,
          ringInfo_translate_optionSpeciesWoman: state.ringInfo_translate_optionSpeciesWoman,
          ringInfo_translate_instructions: state.ringInfo_translate_instructions,
          ringInfo_translate_understand: state.ringInfo_translate_understand,
          ringInfo_language: state.ringInfo_language,
          //UserForm - translate
            //selects
            setUserForm_selects,
            userForm_select_title: state.userForm_select_title,
            userForm_select_inputName: state.userForm_select_inputName,
            userForm_select_inputEmail: state.userForm_select_inputEmail,
            userForm_select_selectCountry: state.userForm_select_selectCountry,
            userForm_select_selectRegion: state.userForm_select_selectRegion,
            userForm_select_selectUSARegion: state.userForm_select_selectUSARegion,
            userForm_select_selectAge: state.userForm_select_selectAge,
            userForm_select_selectEducation: state.userForm_select_selectEducation,
            userForm_select_selectMaritalStatus: state.userForm_select_selectMaritalStatus,
            userForm_select_selectProfession: state.userForm_select_selectProfession,
            userForm_select_selectPersonsInTheHousehold: state.userForm_select_selectPersonsInTheHousehold,
            userForm_select_btn: state.userForm_select_btn,
            userForm_select_error: state.userForm_select_error,
            userForm_select_option_other: state.userForm_select_option_other,
            userForm_language: state.userForm_language,
            //Options
            setFormData_options,
            countryRegion_options: state.countryRegion_options,
            education_options: state.education_options,
            maritalStatus_options: state.maritalStatus_options,
            profession_options: state.profession_options,
            userData_options_language: state.userData_options_language,

        // Language + genders
        setUserLanguage,
        userLanguage: state.userLanguage,

        setUserGender,
        userGender: state.userGender,

        setUserSpecies,
        userSpecies: state.userSpecies,

        // WordSet basic
        setWordSet_basic,
        wordSetBaisc_full: state.wordSetBaisc_full,
        wordSetBasic_words: state.wordSetBasic_words,
        wordSet_language: state.wordSet_language,

        // User
        setUser,
        userName: state.userName,
        userEmail: state.userEmail,
        userCountry: state.userCountry,
        userRegion: state.userRegion,
        usaRegion: state.usaRegion,

        userAge: state.userAge,
        userEducation: state.userEducation,
        userMaritalStatus: state.userMaritalStatus,
        userProfession: state.userProfession,
        userPersonsHouse: state.userPersonsHouse,
        // ------------------------------
        //Timer
        setTimerIsActive,
        timerIsActive: state.timerIsActive,
        setTimerMillisecondsReset,
        timerMillisecondsReset: state.timerMillisecondsReset,
        setTimerWordColorSetMilliseconds,
        timerWordColorSetMilliseconds: state.timerWordColorSetMilliseconds,
        setTimerWordTimerUserResponses,
        timerWordTimerUserResponses: state.timerWordTimerUserResponses,

        // Back
        setTimerBackBTN,
        timerBackBTN: state.timerBackBTN,
        setTimerNumberBack,
        timerNumberBack: state.timerNumberBack,
        setTimerNumberBackResponses,
        timerNumberBackResponses: state.timerNumberBackResponses,
        // ----------------------------------
        // Frist and Last color set
        setFristColorSet,
        fristColorSet: state.fristColorSet,
        setLastColorSet,
        lastColorSet: state.lastColorSet,

        // Color values (rest with new word)
        setOneWordColorArray,
        oneWordColorArray: state.oneWordColorArray,

        //Save rings values (act. word + values for 1 word)
        setColorObject,
        actualWordIndex: state.actualWordIndex,
        userResponses: state.userResponses,

        urlServer: state.urlServer,

        // Reset Context
        resetAll,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
