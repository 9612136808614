import "./scss/ColorRing.scss";
import { useContext, useEffect, useState, lazy, Suspense } from "react";
import { GlobalContext } from "../global/GlobalContext";
// import { useToken_company } from "../hooks/Pages/ColorRing/useToken_company";
import { useToken_active } from "../hooks/Pages/ColorRing/useToken_active";

import Code from "../components/pages/code/Code";
import BtnBackForm from "../components/pages/ColorRing/btnBackForm/BtnBackForm";


const RingInfo = lazy(() =>
  import("../components/pages/ColorRing/RingInfo/Ring_Info")
);
const UserForm = lazy(() =>
  import("../components/pages/ColorRing/UserForm/UserForm")
);
const MainColorRing = lazy(() =>
  import("../components/pages/ColorRing/MainColorRing/MainColorRing")
);
const RingEnd = lazy(() =>
  import("../components/pages/ColorRing/RingEnd/RingEnd")
);
const TokenUnactive = lazy(() =>
  import("../components/pages/ColorRing/TokenUnactive/TokenUnactive")
);

// --------------------------

const ColorRing = () => {
  const {
    setPhase,
    phase,
    userLanguage,
    g_localStorage_loading,
    wordSetBasic_words,
    profession_options,
    token_active,
    //- check
    userProfession,
    token_active_complete,
    //test
    token_active_time,
  } = useContext(GlobalContext);


  const [loading, setLoading] = useState(true);
  const [infoOK, setInfoOK] = useState(true);
  const [endOK, setEndOK] = useState(false);


  // ------------------------------------

  // - custom hooks
  // - Token active control
  useToken_active();

  // -------------------------------------------

  //-Loading localStorage + setPhase
  useEffect(() => {
    if (phase === 2 ) {
      setInfoOK(false);
      setPhase(3, 2);
    }
    if (g_localStorage_loading === 1) {
      setLoading(false);
      // console.log(userName)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLanguage, g_localStorage_loading]);

  // -------------------------------------------


  // test
  console.log("Tokem active complete: "+token_active_complete)
  // console.log("Token start timee: " + token_start_time)
  console.log("token active time: "+token_active_time)




  return (
    <div className="ColorRing">
      {/* TO:DO dodělat ukladání do localstorage a načítání dat jazyka až bude vše hotové */}
      {!loading && !token_active && !token_active_complete > 0 && <Code setLoading={setLoading} />}
      
      {/* Loading */}
      {!loading && token_active && !token_active_complete && (
        <>
          {/* BTN Back */}
          <BtnBackForm infoOK={infoOK} setInfoOK={setInfoOK} />

          <Suspense fallback={<div></div>}>
            {/* Ring Info */}
            {infoOK && !endOK && !userProfession && <RingInfo setInfoOK={setInfoOK} />}

            {/* User data inputs */}
            {!infoOK &&
              !endOK &&
              !userProfession
              &&
              profession_options.length > 0 && <UserForm />}

            {/* Ring */}
            {userProfession && !endOK && wordSetBasic_words.length > 0 && (
              <MainColorRing setEndOK={setEndOK} />
            )}

            {/* Final Screen */}
            {endOK && <RingEnd />}
          </Suspense>
        </>
      )}
      
      {/* token unactive */}
      <Suspense fallback={<div></div>}>
        {(token_active_complete !== 0) && !endOK && (
          <TokenUnactive setLoading={setLoading} setInfoOK={setInfoOK} />
        )}
      </Suspense>
    </div>
  );
};

export default ColorRing;
