import { useContext, useEffect } from "react";
import { GlobalContext } from "../global/GlobalContext";

import localStorage_get from "../utils/localStorage/localStorage_get";

const useLocalStorageGet = (
  setG_localStorage_loading,
  g_localStorage_loading
) => {
  const {
    setToken_active, 
    setToken_active_complete,
    // - token unactive - translate
    setTokenUnactive_translate,
    // - ring info - translate
    setRingInfo_translate,
    // - userForm_selects,
    setUserForm_selects,
    setFormData_options,
    setWordSet_basic,
    setUserLanguage,
    setUserGender,
    setUserSpecies,
    setUser,

    setFristColorSet,
    setColorObject,
    setLastColorSet,
    setTimerWordTimerUserResponses,
    setTimerNumberBackResponses,
  } = useContext(GlobalContext);

  // ----------------------------------------------------------

  useEffect(() => {
    if (g_localStorage_loading <= 1) {
      // fetch

      if(localStorage_get("token_active")) {
        const token_a = localStorage_get("token_active")
        setToken_active(token_a.token_active, token_a.token_active_time)
        console.log(token_a)
      } 

      if(localStorage_get("token_complete")) {
        const token_c = localStorage_get("token_complete");
        setToken_active_complete(token_c.token_complete)
        console.log(token_c)
      } else {
        console.log("-----------------nic")
      }

      // wordSet_basic
      if (localStorage_get("allWord_basic")) {
        const wordSet_b = localStorage_get("allWord_basic");
        setWordSet_basic(
          wordSet_b.wordSetBaisc_full,
          wordSet_b.wordSetBasic_words,
          wordSet_b.wordSet_language,
        );
      }

      // TokenUnactive_translate
      if(localStorage_get("TokenUnactive_translate")){
        const tokenUnactive = localStorage_get("TokenUnactive_translate");
        setTokenUnactive_translate(
          tokenUnactive.tokenUnactive_translate_h1_1,
          tokenUnactive.tokenUnactive_translate_h1_2,
          tokenUnactive.tokenUnactive_translate_text,
          tokenUnactive.tokenUnactive_translate_btn,
          tokenUnactive.tokenUnactive_language,
        )
      }

      // ringInfo_translate
      if(localStorage_get("RingInfo_translate")){
        const ringInfo = localStorage_get("RingInfo_translate");
        setRingInfo_translate(
          ringInfo.title,
          ringInfo.optionMan,
          ringInfo.optionWoman,
          ringInfo.optionOther,
          ringInfo.selectGender,
          ringInfo.selectSpecies,
          ringInfo.optionSpeciesMan,
          ringInfo.optionSpeciesWoman,
          ringInfo.instructions,
          ringInfo.understand,
          ringInfo.language
        )
        console.log(ringInfo)
      }

      //FormData selects
      if(localStorage_get("formData_selects")){
        const formData_s = localStorage_get("formData_selects");
        setUserForm_selects(
          formData_s.title,
          formData_s.inputName,
          formData_s.inputEmail,
          formData_s.selectCountry,
          formData_s.selectRegion,
          formData_s.selectUSARegion,
          formData_s.selectAge,
          formData_s.selectEducation,
          formData_s.selectMaritalStatus,
          formData_s.selectProfession,
          formData_s.selectPersonsInTheHousehold,
          formData_s.btn,
          formData_s.error,
          formData_s.option_other,
          formData_s.language,
        );
      }

      // FormData options
      if (localStorage_get("formData_options")) {
        const formData_o = localStorage_get("formData_options");
        setFormData_options(
          formData_o.countryRegion_options,
          formData_o.education_options,
          formData_o.maritalStatus_options,
          formData_o.profession_options,
          formData_o.userData_options_language,
        );
      }

      // language
      if(localStorage_get("language")){
        const language = localStorage_get("language");
        setUserLanguage(language.userLanguage);
        console.log(language.userLanguage)
      }

      // Gender TO:DO - upravit jen na Gender a species
      if(localStorage_get("genderAndSpecies")){
        const language = localStorage_get("genderAndSpecies");
        // setUserLanguage(language.language);
          setUserGender(language.userGender);
          setUserSpecies(language.userSpecies);
      }

      // user
      if(localStorage_get("user")){
        const user = localStorage_get("user");
        setUser(
          user.name || "",
          user.email || "",
          user.country || "",
          user.region || "",
          user.usaRegion || "",
          user.age || "",
          user.education || "",
          user.maritalStatus || "",
          user.profession || "",
          user.personsInTheHousehold || "",
        )
      }

      // fristColorSet
      if(localStorage_get("fristColorSet")){
        const fristColorSet = localStorage_get("fristColorSet")
        setFristColorSet(fristColorSet.HELPER_FristColorSet);
      }

      // colorObject
      if(localStorage_get("colorObject")){
        const colorObject = localStorage_get("colorObject");
        setColorObject(
          colorObject.actualWordIndex,
          colorObject.HELPER_UserResponses
        );
      }

      // data_lastColorSet
      if(localStorage_get("lastColorSet")){
        const lastColorSet = localStorage_get("lastColorSet");
        setLastColorSet(lastColorSet.HELPER_LastColorSet);
      }

      // data_timerWordUserResponses
      if(localStorage_get("timerWordUserResponses")) {
        const timerWordUserResponses = localStorage_get("timerWordUserResponses");
        setTimerWordTimerUserResponses(timerWordUserResponses.HELPER_timerWordTimerUserResponses);
      }

      // data_numberBackResponse
      if(localStorage_get("numberBackResponse")){
        const numberBackResponse = localStorage_get("numberBackResponse");
        setTimerNumberBackResponses(numberBackResponse.HELPER_numberBackResponse);
      }

    // Chack for loading complete LocalStorage for component start
    setG_localStorage_loading(g_localStorage_loading + 1);

    console.log("Počet průchodu LS: " + g_localStorage_loading);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    g_localStorage_loading,
  ]);
  // ----------------------------------------------------------------

  // LocalStorage Key

  // token_active
  // token_complete
  // TokenUnactive_translate
  // RingInfo_translate
  // formData_selects
  // formData_options
  // allWord_basic
  //user
  // language
  //genderAndSpecies
  //fristColorSet
  //colorObject
  //lastColorSet

  //timerWordUserResponses
  //numberBackResponse

  //langauge_TokenInactive_texts
};

export default useLocalStorageGet;
