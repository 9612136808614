import { useEffect } from "react";
// import localStorage_set from "../../../utils/localStorage/localStorage_set";

// Vytvoříme funkci pro volání naší backendové routy pro překlad
const translateTexts = async (urlServer, texts, language) => {
    const response = await fetch(`${urlServer}/deepl_unAuthorization`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        language,
        texts,
      }),
    });
  
    if (!response.ok) {
      throw new Error("Translation request failed");
    }
  
    const translatedTexts = await response.json();
    return translatedTexts;
  };
  
  // -----------------------------

  export const useTranslation_code = (
    userLanguage,
    original_text,
    urlServer,
    setCode_translate,
    code_t_language,
    setLoading
  ) => {
  
    useEffect(() => {
      const translateContent = async () => {
        try {
            const texts = [
                original_text.title_language,
                original_text.title_code,
                original_text.info1,
                original_text.info2,
                original_text.btn,
              ];
  
          // Zavoláme backendovou routu a získáme přeložené texty
          const translatedTexts = await translateTexts(
            urlServer,
            texts,
            userLanguage
          );

  
          // Uložit přeložené texty do localStorage nebo použít v komponentě
        //   const localStorage_object = {
        //     title_language: translatedTexts[1],
        //     title_code: translatedTexts[2],
        //     info1: translatedTexts[3],
        //     info2: translatedTexts[4],
        //     btn: translatedTexts[5],
        //     language: userLanguage,
        //   };
        //   localStorage_set(localStorage_object, "RingInfo_translate");
  
          // Nebo můžeš místo ukládání rovnou nastavit překlad přímo v komponentě
          setCode_translate(
            translatedTexts[0],
            translatedTexts[1],
            translatedTexts[2],
            translatedTexts[3],
            translatedTexts[4],
            userLanguage
          );

        } catch (error) {
          console.error("Translation error:", error);
        } finally {
          setLoading(false);
        }
      };
  
      if (userLanguage && userLanguage !== "CS" && code_t_language !== userLanguage) {
        translateContent();
      } else if (userLanguage || userLanguage === "CS"){
        setLoading(false);
      }
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLanguage, urlServer, original_text]);
  };