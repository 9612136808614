import { mainState } from './GlobalContext';

const GlobalReducer = (state, action) => {
    switch (action.type) {
      
      // token
      // token active
    case "SET_TOKEN_ACTIVE":
      return {
        ...state,
        token_active: action.payload.token_active,
        token_active_time: action.payload.token_active_time,
      }
      // token complete
    case "SET_TOKEN_ACTIVE_COMPLETE":
      return {
        ...state,
        token_active_complete: action.payload
      }
    
      // localStorage loading
    case "SET_G_LOCALSTORAGE_LOADING":
      return {
        ...state,
        g_localStorage_loading: action.payload,
      }

      // Phase Page
    case "SET_PHASE":
      return {
        ...state,
        phase: action.payload.phase,
        maxPhase: action.payload.maxPhase,
      }

      // ---------------------------

      // Fetch
      // WordSet_basic
    case "SET_COLOR_SET_BASIC":
      return {
        ...state,
        wordSetBaisc_full: action.payload.wordSetBaisc_full,
        wordSetBasic_words: action.payload.wordSetBasic_words,
        wordSet_language: action.payload.wordSet_language,
      }
      // code - translate
    case "SET_CODE_TRANSLATE" :
      return {
        ...state,
        code_t_title_language: action.payload.code_t_title_language,
        code_t_title_code: action.payload.code_t_title_code,
        code_t_info1: action.payload.code_t_info1,
        code_t_info2: action.payload.code_t_info2,
        code_t_btn: action.payload.code_t_btn,
        code_t_language: action.payload.code_t_language,
      }
      // tokenUnactive - translate
    case "SET_TOKEN_UNACTIVE_TRANSLATE":
      return {
        ...state,
        tokenUnactive_translate_h1_1: action.payload.tokenUnactive_translate_h1_1,
        tokenUnactive_translate_h1_2: action.payload.tokenUnactive_translate_h1_2,
        tokenUnactive_translate_text: action.payload.tokenUnactive_translate_text,
        tokenUnactive_translate_btn: action.payload.tokenUnactive_translate_btn,
        tokenUnactive_language: action.payload.tokenUnactive_language,
      }
      // ringInfo - translate
    case "SET_RING_INFO_TRANSLATE":
      return {
        ...state,
        ringInfo_translate_title: action.payload.ringInfo_translate_title,
          // ringInfo_translate_selectLanguage: action.payload.ringInfo_translate_selectLanguage,
          ringInfo_translate_optionMan: action.payload.ringInfo_translate_optionMan,
          ringInfo_translate_optionWoman: action.payload.ringInfo_translate_optionWoman,
          ringInfo_translate_optionOther: action.payload.ringInfo_translate_optionOther,
          ringInfo_translate_selectGender: action.payload.ringInfo_translate_selectGender,
          ringInfo_translate_selectSpecies: action.payload.ringInfo_translate_selectSpecies,
          ringInfo_translate_optionSpeciesMan: action.payload.ringInfo_translate_optionSpeciesMan,
          ringInfo_translate_optionSpeciesWoman: action.payload.ringInfo_translate_optionSpeciesWoman,
          ringInfo_translate_instructions: action.payload.ringInfo_translate_instructions,
          ringInfo_translate_understand: action.payload.ringInfo_translate_understand,
          ringInfo_language: action.payload.ringInfo_language,
      }
      // formData_selects
    case "SET_USER_FORM_SELECTS": 
    return {
      ...state,
      userForm_select_title: action.payload.userForm_select_title,
      userForm_select_inputName: action.payload.userForm_select_inputName,
      userForm_select_inputEmail: action.payload.userForm_select_inputEmail,
        userForm_select_selectCountry: action.payload.userForm_select_selectCountry,
        userForm_select_selectRegion: action.payload.userForm_select_selectRegion,
        userForm_select_selectUSARegion: action.payload.userForm_select_selectUSARegion,
        userForm_select_selectAge: action.payload.userForm_select_selectAge,
        userForm_select_selectEducation: action.payload.userForm_select_selectEducation,
        userForm_select_selectMaritalStatus: action.payload.userForm_select_selectMaritalStatus,
        userForm_select_selectProfession: action.payload.userForm_select_selectProfession,
        userForm_select_selectPersonsInTheHousehold: action.payload.userForm_select_selectPersonsInTheHousehold,
        userForm_select_btn: action.payload.userForm_select_btn,
        userForm_select_error: action.payload.userForm_select_error,
        userForm_select_option_other: action.payload.userForm_select_option_other,
        userForm_language: action.payload.userForm_language,
    }
      // FormData_options
    case "SET_FORM_DATA_OPTIONS": 
      return {
        ...state,
        countryRegion_options: action.payload.countryRegion_options,
        education_options: action.payload.education_options,
        maritalStatus_options: action.payload.maritalStatus_options,
        profession_options: action.payload.profession_options,
        userData_options_language: action.payload.userData_options_language,
      }

      // ---------------------

      // USER
    case "SET_USER_LANGUAGE":
      return {
        ...state,
        userLanguage: action.payload,
      }

    case "SET_USER_GENDER":
      return {
        ...state,
        userGender: action.payload,
      }

    case "SET_USER_SPECIES":
      return {
        ...state,
        userSpecies: action.payload,
      }
      
    

    case "SET_USER":
      return {
        ...state,
        userName: action.payload.userName,
        userEmail: action.payload.userEmail,
        userCountry: action.payload.userCountry,
        userRegion: action.payload.userRegion, 
        usaRegion: action.payload.usaRegion,

        userAge: action.payload.userAge, 
        userEducation: action.payload.userEducation,    
        userMaritalStatus: action.payload.userMaritalStatus, 
        userProfession: action.payload.userProfession, 
        userPersonsHouse: action.payload.userPersonsHouse
      }

      // Timer
    case "SET_TIMER_IS_ACTIVE":
      return{
        ...state,
        timerIsActive: action.payload
      }
    case "SET_TIMER_MILLISECONDS_RESET":
      return{
        ...state,
        timerMillisecondsReset: action.payload
      }
    case "SET_TIMER_WORD_COLOR_SET_MILLISECONDS":
      return{
        ...state,
        timerWordColorSetMilliseconds: action.payload
      }
    case "SET_TIMER_WORD_USER_RESPONSES":
      return {
        ...state,
        timerWordTimerUserResponses: action.payload
      }
    
      // Back
    case "SET_TIMER_BACK_BTN":
      return {
        ...state,
        timerBackBTN: action.payload
      }
    case "SET_TIMER_NUMBER_BACK":
      return {
        ...state,
        timerNumberBack: action.payload
      } 
    case "SET_TIMER_NUMBER_BACK_RESPONSES":
      return {
        ...state,
        timerNumberBackResponses: action.payload
      }
    // -----------------------
      
    case "SET_FRIST_COLOR_SET":
      return {
        ...state,
        fristColorSet: action.payload,
      }

    case "SET_LAST_COLOR_SET":
      return {
        ...state,
        lastColorSet: action.payload,
      }
      // 
    case "SET_ONE_WORD_COLOR_ARRAY": 
      return {
        ...state,
        oneWordColorArray: action.payload,
      }

    case "SET_COLOR_OBJECT":
      return {
        ...state,
        actualWordIndex: action.payload.actualWordIndex,
        userResponses: action.payload.userResponses
      }
    
      // Retrun All default
    case "RESET_ALL":
      return {
        ...mainState
      };

      default:
        return state;
    }
  };
  
  export default GlobalReducer;