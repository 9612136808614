// import { useContext } from "react";
// import "./LanguageSelect.scss";
// import { GlobalContext } from "../../../../global/GlobalContext";
// import SelectMore_language from "../../../elements/selects/SelectMore/SelectMore_language";
// import languages_name from "../../../../data/languages/languages_name";
// // import localStorage_set from "../../../../utils/localStorage/localStorage_set";

// const LanguageSelect = ({ original_text, translate_text }) => {
//   const { userLanguage, setUserLanguage } = useContext(GlobalContext);

//   console.log("načteni"+userLanguage)
//   // Funkce pro uložení jazyka do localStorage a kontextu
//   const saveLanguage = (selectedOption) => {
//     const newLanguage = selectedOption?.value || languages_name[0].value;

//     // // Uložení nového jazyka do localStorage
//     // const localStorageObject = { userLanguage: newLanguage };
//     // localStorage_set(localStorageObject, "language");

//     // Aktualizace jazyka v kontextu aplikace
//     setUserLanguage(newLanguage);
//   };

//   // Najít objekt odpovídající aktuálnímu jazyku (userLanguage)
//   const defaultLanguageOption = languages_name.find(lang => lang.value === userLanguage);

//   return (
//     <div className="LanguageSelect">
//       <SelectMore_language
//         placeholder={userLanguage !== "CS" ? translate_text : original_text}
//         object={languages_name}
//         onChange={(selectedOption) => {
//           // Zavolání funkce pro uložení nového jazyka
//           saveLanguage(selectedOption);
//         }}
//         defaultValue={defaultLanguageOption}
//       />
//     </div>
//   );
// };

// export default LanguageSelect;

import { useContext, useEffect, useState } from "react";
import "./LanguageSelect.scss";
import { GlobalContext } from "../../../../global/GlobalContext";
import SelectMoreLanguage from "../../../elements/selects/SelectMore/SelectMore_language";
import languages_name from "../../../../data/languages/languages_name";

const LanguageSelect = ({ original_text, translate_text }) => {
  const { userLanguage, setUserLanguage } = useContext(GlobalContext);
  const [defaultLanguageOption, setDefaultLanguageOption] = useState(null);

  useEffect(() => {
    // Najít objekt odpovídající aktuálnímu jazyku
    const selectedLanguage = languages_name.find(lang => lang.value === userLanguage);
    setDefaultLanguageOption(selectedLanguage || languages_name[0]); // Nastavit výchozí možnost
  }, [userLanguage]);

  const saveLanguage = (selectedOption) => {
    const newLanguage = selectedOption?.value || languages_name[0].value;
    setUserLanguage(newLanguage);
  };

  // Podmíněné vykreslení, pokud je `defaultLanguageOption` načten
  if (!defaultLanguageOption) {
    return null; // nebo můžete zobrazit načítací indikátor
  }

  return (
    <div className="LanguageSelect">
      <SelectMoreLanguage
        placeholder={userLanguage !== "CS" ? translate_text : original_text}
        object={languages_name}
        onChange={saveLanguage}
        defaultOption={defaultLanguageOption}  // Předání defaultValue
      />
    </div>
  );
};

export default LanguageSelect;