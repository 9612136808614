// import { NavLink } from "react-router-dom";
// import { useState, useContext } from "react";
// import { HiMenu, HiHome } from "react-icons/hi";
import { useContext } from "react";
import "./scss/Header.scss";
import mainLogo from "../../../img/novitim_logo_web_250.png";
import { GlobalContext } from "../../GlobalContext";

const Header = () => {
  const { userProfession } = useContext(GlobalContext);
  // const [showMenu, setShowMenu] = useState(false);

  // const hideMenu = () => {
  //   setShowMenu(false);
  // };

  return (
    <header className={`${userProfession? "whiteBG" : ""}`}>

        <>
          <div className="nav-header">
            <div className="logo">
              <img src={mainLogo} alt="Novitim Logo" />
            </div>

            {/* <button
              className="humburger-button"
              onClick={() => setShowMenu(!showMenu)}
            >
              <HiMenu className="hamburger-icon" />
            </button> */}
          </div>

          <div className="nav-box">
            {/* <nav className={`${showMenu ? "show" : "hide"}`}>
              <NavLink onClick={hideMenu} to="/">
                <HiHome className="icon" /> Kruh
              </NavLink>
            </nav> */}
          </div>
        </>

    </header>
  );
};

export default Header;
