import { BrowserRouter, Routes, Route } from "react-router-dom"
import { GlobalProvider } from "./global/GlobalContext"
import SharedLayouts from "./global/layouts/SharedLayouts"
// Pages
import ColorRing from "./pages/ColorRing"
import Error from "./pages/Error"

const App = () => {
  return (
    <>
      <GlobalProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<SharedLayouts />}>
              <Route path="*" element={<Error />} />
              <Route index element={<ColorRing />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </GlobalProvider>
    </>
  )
}

export default App