const languages_label = [
  { value: "CS", label: "Čeština" }, // Czech
  { value: "SK", label: "Slovenčina" }, // Slovak
  { value: "EN-GB", label: "English (British)" }, // English (British)
  { value: "EN-US", label: "English (American)" }, // English (American)
  { value: "DE", label: "Deutsch" }, // German
  { value: "FR", label: "Français" }, // French
  { value: "NL", label: "Nederlands" }, // Dutch
  { value: "IT", label: "Italiano" }, // Italian
  { value: "ES", label: "Español" }, // Spanish
  { value: "SV", label: "Svenska" }, // Swedishx
  { value: "ZH", label: "简体中文" }, // Chinese (Simplified)
  { value: "ZH-HANT", label: "繁體中文" }, // Chinese (Traditional)
  { value: "AR", label: "العربية" }, // Arabic
  { value: "BG", label: "Български" }, // Bulgarian
  { value: "DA", label: "Dansk" }, // Danish
  { value: "ET", label: "Eesti" }, // Estonian
  { value: "FI", label: "Suomi" }, // Finnish
  { value: "EL", label: "Ελληνικά" }, // Greek
  { value: "HU", label: "Magyar" }, // Hungarian
  { value: "ID", label: "Bahasa Indonesia" }, // Indonesian
  { value: "JA", label: "日本語" }, // Japanese
  { value: "KO", label: "한국어" }, // Korean
  { value: "LV", label: "Latviešu" }, // Latvian
  { value: "LT", label: "Lietuvių" }, // Lithuanian
  { value: "NB", label: "Norsk Bokmål" }, // Norwegian Bokmål
  { value: "PL", label: "Polski" }, // Polish
  { value: "PT-BR", label: "Português (Brasil)" }, // Portuguese (Brazilian)
  { value: "PT-PT", label: "Português (Europeu)" }, // Portuguese (European)
  { value: "RO", label: "Română" }, // Romanian
  { value: "RU", label: "Русский" }, // Russian
  { value: "SL", label: "Slovenščina" }, // Slovenian
  { value: "TR", label: "Türkçe" }, // Turkish
  { value: "UK", label: "Українська" }, // Ukrainian
];

export default languages_label;
