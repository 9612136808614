import { useEffect, useContext } from "react";
import { GlobalContext } from "../../../global/GlobalContext";

export const useToken_active = () => {
  const { token_active, token_active_time, urlServer } =
    useContext(GlobalContext);

  useEffect(() => {
    
    
    const validateToken = async () => {
      const currentTime = Date.now() / 1000; //second

      // if token expires and isnt complete
      if (
        token_active_time > 0 &&
        token_active_time < currentTime
      ) {
        console.error("Token exspired. Logging out...");

        // Call backend to clear HttpOnly cookie - with random endpoint (clear over hadleTokenExpiration)
        await fetch(urlServer + "/getUSARegion", {
          method: 'POST',
          credentials: 'include', // Include cookies in the request
        });

        // clear token_active
        const colorObjectJSON = JSON.stringify({
          token_active: false,
          token_active_time: -0,
        });
        localStorage.setItem("token_active", colorObjectJSON);

        // Time out Token
        const tokenCompleteJSON = JSON.stringify({ token_complete: 1});
        localStorage.setItem("token_complete", tokenCompleteJSON);

        return (window.location.href = "/");
      } else {
        console.log("ok")
      }
    };

    // start validate now and every 1 min
    validateToken();
    const intervalId = setInterval(validateToken, 60000);

    // clear
    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token_active]);
};
