import { useContext } from "react";
import { IoCaretBackCircle } from "react-icons/io5";
import "./BtnBackForm.scss";
import { GlobalContext } from "../../../../global/GlobalContext";

const BtnBackForm = ({ setInfoOK, infoOK }) => {
  const { setPhase, phase, maxPhase } = useContext(GlobalContext);

  const btnClick = () => {
    if (infoOK) {
      setPhase(phase - 1, maxPhase);
    } else if (!infoOK) {
      setPhase(phase - 2, maxPhase);
      setInfoOK(true);
    }
  };

  return (
    <div className="BtnBackForm">
      <div>
        {phase < 4 && phase > 0 && (
          <button className="back" onClick={btnClick}>
            <IoCaretBackCircle />
          </button>
        )}
      </div>

      {(phase < 2 && phase < maxPhase) && (
        <button className="next" onClick={() => setPhase(phase + 1, maxPhase)}>
          <IoCaretBackCircle />
        </button>
      )}
    </div>
  );
};

export default BtnBackForm;
