import { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Code.scss";
import { GlobalContext } from "../../../global/GlobalContext";
import InputContainer from "../../elements/inputs/InputContainer/InputContainer";
import localStorage_set from "../../../utils/localStorage/localStorage_set";

// language + translate
import LanguageSelect from "./LanguageSelect/LanguageSelect";
import { useTranslation_code } from "../../../hooks/Pages/Code/useTranslation_code";

const Code = ({ setLoading }) => {
  const {
    urlServer,
    setToken_active,
    setUser,
    userLanguage,
    setCode_translate,
    code_t_language,
    // tanslate texts
    code_t_title_language,
    code_t_title_code,
    code_t_info1,
    code_t_info2,
    code_t_btn,
    // test
    // token_active_complete
  } = useContext(GlobalContext);

  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  const original_text = {
    title_language: "Vyberte si jazyk",
    title_code: "Zadejte Váš kód",
    info1: "Zadejte kód, který jste obdrželi na Váš e-mail.",
    info2: "Po potvrzení budete mít na průchod 45 minut.",
    btn: "Začít",
  };

  // -----------------
  // Get code from URL
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlCode = params.get("code");
    if (urlCode) {
      setCode(urlCode);
    }
  }, [location]);

  // -----------------

  useTranslation_code(
    userLanguage,
    original_text,
    urlServer,
    setCode_translate,
    code_t_language,
    setLoading
  );

  // Clear Error
  useEffect(() => {
    if (error) {
      setError();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLanguage, code, setCode_translate, code_t_language]);

  // -----------------

  const btnClick = async () => {
    const response = await fetch(urlServer + "/loading_newRespondent", {
      method: "post",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        code,
        targetLang: userLanguage,
      }),
    });
    const data = await response.json();

    if (data.fetch_data?.name) {
      // TO:DO - je to třeba?
      const currentTime = Date.now();
      const tokenActiveTime = (currentTime + 45 * 60 * 1000) / 1000; // Přidej 45 minut

      // Set language
      // Uložení nového jazyka do localStorage
      let localStorage_object = { userLanguage };
      localStorage_set(localStorage_object, "language");

      // Set token_actvie LS + GC
      localStorage_object = {
        token_active: true,
        token_active_time: tokenActiveTime,
      };
      localStorage_set(localStorage_object, "token_active");
      setToken_active(true, data.fetch_data.currentTime, tokenActiveTime);

      // set User LS + GC
      localStorage_object = {
        name: data.fetch_data.name,
        email: data.fetch_data.email,
      };
      localStorage_set(localStorage_object, "user");
      setUser(
        data.fetch_data.name,
        data.fetch_data.email,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      );
    } else {
      return setError(data.msg);
    }
  };

  return (
    <div className="Code">
      <div className="window">
        <div className="title">
          <h1>
            {userLanguage === "CS"
              ? original_text.title_code
              : code_t_title_code}
          </h1>
        </div>
        <div className="info">
          <p>{userLanguage === "CS" ? original_text.info1 : code_t_info1}</p>
          <p>{userLanguage === "CS" ? original_text.info2 : code_t_info2}</p>
        </div>
        <div className="input">
          <InputContainer
            type="text"
            label={
              userLanguage === "CS"
                ? original_text.title_code
                : code_t_title_code
            }
            id="code"
            value={code}
            set={setCode}
            regexSetting="email"
          />
        </div>
        <p className="error">{error}</p>
        <div className="button_box">
          {code && (
            <button onClick={btnClick}>
              {userLanguage === "CS" ? original_text.btn : code_t_btn}
            </button>
          )}
        </div>

        {/* Language */}
        <LanguageSelect
          original_text={original_text.title_language}
          translate_text={code_t_title_language}
        />
      </div>
    </div>
  );
};

export default Code;
